.multiselectContainer {
    text-color: red
}

.searchBox {
  background: white;
}

.inputField {}
.chips {}
.optionContainer {}
.option {}
.groupHeading {}