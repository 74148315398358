.my-navbar-bg {
    background-color: #71788E;
    color: slategray;
    opacity: 0.8;
}

.home-carousel.carousel .carousel-item img {
    object-fit: cover;
    height: calc(100vh - 100px);
}

.property-images.carousel .carousel-item {
    width: 100%;
    height: 300px;
}

.property-images.carousel .carousel-item img {
    margin: auto;
    max-width: 100%;
    max-height: content-box;
    vertical-align: center;
}

.property-images.carousel-inner > .item {
    width: 100%;
}
.carousel-caption > h3{
    font-size: 1.2rem;
}
.carousel-caption > p{
    font-size: 12px;
}
@media (min-width: 576px) {
    .property-images.carousel .carousel-item {
        height: 300px;
    }

    .property-images.carousel .carousel-item img {
        margin: auto;
        max-height: 300px;
    }

    .property-images.carousel-inner > .item {
        height: 300px;
    }
    .carousel-caption > h3{
        font-size: 1.75rem;
    }
    .carousel-caption > p{
    font-size: 16px;
    }
}


@font-face {
    font-family: TradeMark;
    src: local("TradeMark"), url("fonts/TradeMarkDemo.otf") format("opentype");

}

.MHFont {
    font-family: "TradeMark", Arial;
    font-size: xx-large;
    color: #ffd700;
}

.gradient {
    background-image: linear-gradient(90deg, #000000, #71788e 22%, #e8e9ed);
}

.gradient2 {
    background-image: linear-gradient(90deg, #71788e 18%, #e8e9ed);
}

.gradline {
    background-image: linear-gradient(to right, rgba(211, 211, 211, 0), rgba(211, 211, 211, 0.3) 20%, rgba(211, 211, 211, 0.3) 80%, rgba(211, 211, 211, 0));
}

a.nav-link:hover {
    color: #d5cda2 !important;
}

body {
    padding-top: 110px;
}

@media (min-width: 576px) {
    body {
        padding-top: 100px;
    }
}

.card-columns {
    column-count: 1;
}

@media (min-width: 560px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 1200px) {
    .card-columns {
        column-count: 3;
    }
}

.sidesearch .input-group label.form-label {
    width: 75%;
}

.advancedsearch .input-group label.form-label {
    display: block;
}

.advancedsearch .input-group {
    display: block;
}

.advancedsearch .extrastyle {
    display: inline-block;
    width: 20%;
}

.bg-image {
    /* The image used */
    background-image: url("images/nappali09.jpg");

    /* Add the blur effect */
    filter: blur(4px);
    -webkit-filter: blur(4px);
    opacity: 0.4;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-image2 {
    background-image: url("images/service3.jpg");
    filter: blur(4px);
    -webkit-filter: blur(4px);
    opacity: 0.4;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-image3 {
    background-image: url("images/service4.jpg");
    filter: blur(4px);
    -webkit-filter: blur(4px);
    opacity: 0.4;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.carousel-caption {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

div._2iA8p44d0WZ-WqRBGcAuEV {
    border: #ced4da solid 1px;
    background: white;
}

._2iA8p44d0WZ-WqRBGcAuEV input.searchBox {
    background: white;
}

.inputField {
}

.chips {
}

div.optionListContainer {
    background: white;
}

.optionContainer {
    background: white;
    color: black;
}

.option {
}

.groupHeading {
}

.propertyListCard .card-img {
    max-height: 300px;
    object-fit: contain;
}

.propertyListCard .card-title {
    font-size: medium;
}

.spacer {
    flex: 1;
}

.privacy-document table {
    border-collapse: collapse;
    caption-side: top !important;
    margin-bottom: 1em;
}

.privacy-document table > caption {
    caption-side: top !important;
}

.privacy-document table, th, td {
    border: 1px solid black;
}

.privacy-document th, td {
    padding: 5px;
    text-align: left;
}

html, body, #root {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
}

#root {
    min-height: 100%;
    height: auto !important;
}
.propertyListCard {
    cursor: pointer;
    width: 100%
}

@media (min-width: 576px) {
    .propertyListCard {

        width: 30%
    }
}

@media (min-width: 768px) {
    .propertyListCard {

        width: 45%
    }
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}

@media (min-width: 768px) {
    .suggestions .propertyListCard {
        width: 30%
    }
}
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feature-inactive {
    cursor: not-allowed !important;
}
.contactCardText{ /*mobil nézet*/
    font-size: 5vw;
}
@media (min-width: 768px) {
    .contactCardText{
        font-size: 1vw;

    }
}
.carousel-control-prev-icon, .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.3);
    background-size: 100%, 100%;
    border-radius: 50%;
    }

.image-popup-modal-button-left{
    top:50%;
    left: 0;
    position: absolute;
    opacity: 0.8;
    border-radius: 50%;
}
.image-popup-modal-button-left:hover{
    opacity: 1;
}

.image-popup-modal-button-right{
    top:50%;
    right: 0;
    position: absolute;
    opacity: 0.8;
    border-radius: 50%;
}
.image-popup-modal-button-right:hover{
    opacity: 1;
}
.kapcsolat-bg{
    align-items: center;
}
.kapcsolat-bg::before{
    content: "";
    background-image: url("images/MHiroda.jpg");
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.3;
}
.flex-container{
    display: flex;
    flex-wrap: wrap;
}


.flex-item-right, .flex-item-left {
    flex: 100%;
}
@media (min-width: 992px) {
    .flex-item-left, .flex-item-right {
        flex: 50%;
    }
}