.slider {
    display: block;
    position: fixed;
    background-color: black;
    z-index: 2147483647;
    height: 100%;
    width: 50%;
    pointer-events: none;
}

#slider-left {
    left: 0;
    transition: left 2s ease-out 0s, opacity 1s ease-out 1500ms;
}

#slider-right {
    right: 0;
    z-index: 2147483646;
    transition: right 2s ease-out 0s, opacity 1s ease-out 1500ms;
}

#slider-left.out {
    left: -50%;
    opacity: 0;
}

#slider-right.out {
    right: -50%;
    opacity: 0;
}

